import React from 'react';
import styled from 'styled-components';
import {Row, Col, Drawer} from 'antd';
import {navigate} from 'gatsby';
import {ToggleDrawer} from '../../Widgets';
import {GridLayout, GridItem} from '../../Components/ProductLayout';
import ProductItem from '../../Components/ProductItem';
import useProductList from '../../hooks/use-product-list';
import TopSection from './TopSection';
import SideSection from './SideSection';
import ContentWrapper from '../../Components/Layout/ContentWrapper';
import {Context} from '../../AppContext';
const slugify = require('uslug');

export default function ProductListPage(props) {
  const {location} = props;
  const {
    loading,
    products,
    filters,
    setFilters,
    applyFilter,
    showDrawer,
    setShowDrawer,
  } = useProductList({location});
  const app = React.useContext(Context);

  return (
    <ContentWrapper>
      <Row style={{marginBottom: 50}}>
        <Col
          xs={0}
          sm={0}
          md={0}
          lg={5}
          style={{
            backgroundColor: 'white',
            minHeight: 'calc(100vh - 60px)',
          }}>
          <SideSection
            filters={filters}
            onFilter={(obj) => setFilters((filters) => ({...filters, ...obj}))}
          />
        </Col>

        <Col md={24} lg={1} />
        <Col xs={24} sm={24} md={24} lg={18}>
          <TopSection
            filters={filters}
            onFilter={(obj) => setFilters((filters) => ({...filters, ...obj}))}
          />

          <GridLayout>
            {loading
              ? null
              : // <LoadingBlock height="700"/>
                applyFilter(products).map((p, idx) => (
                  <GridItem key={idx}>
                    <ProductItem
                      product={p}
                      link={`/product/${p.id}`}
                      onClick={() => {
                        app.actions.setLoading(true);
                        navigate(`/product/${p.id}`);
                      }}
                    />
                  </GridItem>
                ))}
          </GridLayout>
        </Col>
      </Row>

      <ToggleDrawer
        onClick={() => setShowDrawer(true)}
        breakpoint={992}
        style={{color: '#fff'}}>
        {' '}
        {/* 992 = ant design lg brakpoint */}
        商品
        <br />
        列表
      </ToggleDrawer>

      <Drawer
        placement="left"
        closable={false}
        onClose={() => setShowDrawer(false)}
        visible={showDrawer}>
        <SideSection
          filters={filters}
          onFilter={(obj) => setFilters((filters) => ({...filters, ...obj}))}
          onCloseDrawer={() => setShowDrawer(false)}
        />
      </Drawer>
    </ContentWrapper>
  );
}
